import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import loadable from '@loadable/component'

import Account from '../layouts/account'

const urlRedirect = `${process.env.REACT_APP_ACCOUNTS_FRONT_URL}`
const urlCallback = `${process.env.REACT_APP_ACCOUNTS_FRONT_URL}/callback`

const AsyncTerms = loadable(() => import(/* webpackChunkName: "Terms" */ '../containers/legal/terms'))

const AsyncOneTap = loadable(() => import(/* webpackChunkName: "Onetap" */ '../containers/One-Tap'))

const AsyncOneTapSync = loadable(() => import(/* webpackChunkName: "OneTapSync" */ '../containers/One-Tap-Sync'))

const AsyncOneTapAuth =  loadable(() => import(/* webpackChunkName: "OneTapSync" */ '../containers/One-Tap-Auth'))

const AsyncOneTapService = loadable(() => import(/* webpackChunkName: "OneTapService" */ '../containers/One-Tap-Service'))

const AsyncPrivacity = loadable(() => import(/* webpackChunkName: "Privacity" */ '../containers/legal/privacity'))

const AsyncLogout = loadable(() => import(/* webpackChunkName: "Logout" */ '../containers/Logout'))

const AsyncLogin = loadable(() => import(/* webpackChunkName: "Login" */ '../containers/Login'))

const AsyncLoginBarba = loadable(() => import(/* webpackChunkName: "LoginBarba" */ '../containers/LoginBarba'))

const AsyncSignup = loadable(() => import(/* webpackChunkName: "Signup" */ '../containers/Signup'))

const AsyncSignupBarba = loadable(() => import(/* webpackChunkName: "SignupBarba" */ '../containers/SignupBarba'))

const AsyncUsernameRecovery = loadable(() => import(/* webpackChunkName: "UsernameRecovery" */ '../containers/UsernameRecovery'))

const AsyncPasswordRecoveryBarba = loadable(() => import(/* webpackChunkName: "UsernameRecovery" */ '../containers/PasswordRecoveryBarba'))

const AsyncWelcomeUser = loadable(() => import(/* webpackChunkName: "welcomeUser" */ '../containers/welcome'))

const AsyncWelcomeUserBarba = loadable(() => import(/* webpackChunkName: "welcomeUserBarba" */ '../containers/welcomeBarba'))

const AsyncValidateAccount = loadable(() => import(/* webpackChunkName: "ValidateAccount" */ '../containers/validate'))

const AsyncConfirmEmail = loadable(() => import(/* webpackChunkName: "ValidateAccount" */ '../containers/ConfirmEmail'))

const AsyncSetCredentials = loadable(() => import(/* webpackChunkName: "AsyncSetCredentials" */ '../containers/Credentials'))

const AsyncCallback = loadable(() => import(/* webpackChunkName: "AsyncSetCredentials" */ '../containers/Callback'))

const AsyncExternalCode = loadable(() => import(/* webpackChunkName: "AsyncExternalCode" */ '../containers/ExternalCode'))

function Root() {
  return (
    <Router>
      <Switch>
        <Route component={AsyncTerms} exact path='/terms' />
        <Route component={AsyncSetCredentials} exact path='/set-credentials' />
        <Route component={AsyncOneTap} exact path='/onetap/:typeView' />
        <Route component={AsyncOneTapSync} exact path='/onetapsync/:typeView' />
        <Route component={AsyncOneTapAuth} exact path='/onetapauth' />
        <Route component={AsyncOneTapService} exact path='/onetapservice/:typeView' />
        <Route component={AsyncPrivacity} exact path='/privacity' />
        <Route component={AsyncLogout} exact path='/logout' />
        <Route component={AsyncLogin} exact path='/login' />
        <Route component={AsyncConfirmEmail} exact path='/confirmemail' />
        <Route component={AsyncExternalCode} exact path='/externalcode/:typeApp' />
        <Route component={AsyncCallback} exact path='/callback' />
        <Route
          exact
          path='/*' >
          <Account>
            <Switch>
              <Route component={AsyncLoginBarba} exact path='/signinbarba' />
              <Route
                exact path='/signinbarbalaborum'
                render={props => (
                  <AsyncLoginBarba
                    {...props}
                    barbaLaborum />
                )} />
              <Route component={AsyncSignup} exact path='/signup' />
              <Route
                component={AsyncSignupBarba}
                exact  path='/signupbarba' render={props => (
                  <AsyncSignupBarba
                    {...props} />
                )}   />
              <Route
                exact path='/signupbarbalaborum' render={props => (
                  <AsyncSignupBarba
                    {...props}
                    barbaLaborum />
                )}   />
              <Route component={AsyncUsernameRecovery} exact path='/usernamerecovery' />
              <Route component={AsyncPasswordRecoveryBarba} exact path='/passwordrecoverybarba' />
              <Route
                exact path='/passwordrecoverybarbalaborum' render={props => (
                  <AsyncPasswordRecoveryBarba
                    {...props}
                    barbaLaborum />
                )}   />
              <Route component={AsyncWelcomeUser} exact path='/welcome/:id' />
              <Route component={AsyncWelcomeUserBarba} exact path='/welcomebarba/:id' />
              <Route component={AsyncValidateAccount} exact path='/validate/:id/:token' />
              <Route
                exact path='/validatebarba/:id/:token' render={props => (
                  <AsyncValidateAccount
                    {...props}
                    validateBarba />
                )} />
              <Route render={() => <Redirect to={`/login?urlRedirect=${urlRedirect}&urlCallback=${urlCallback}`} />} />
            </Switch>
          </Account>
        </Route>
      </Switch>
    </Router>
  )
}

export default Root
