import Axios from 'axios'
import Oauth2API from './Oauth2API'
import { buildUrlUri } from './utils'

const maintenanceService = 'https://cdn.krowdy.com/auth/service/index.html?userId='

class SIGNIN {
  constructor() {
    this.clientid = process.env.REACT_APP_CLIENT_ID
    this.clientsecret = process.env.REACT_APP_CLIENT_SECRET
  }
  async authenticate() {
    try {
      let accessToken = window.localStorage.getItem('accessToken')
      if(accessToken === null)
        return { data: { success: false } }

      const response = await Oauth2API.externalOperationsJson.get('/api/authenticate')

      return response
    } catch (error) {
      if(error && error.response && error.response.status === 401) {
        console.log(`[Krowdy] - ONETAP : [${error.response.data.message}]`)

        return { data: { success: false } }
      }

      return { data: { success: false } }
    }
  }

  async activeAccount({ email, urlCallback, urlRedirect }) {
    try {
      return await Oauth2API.externalOperationsForm.post(
        '/api/oauth/resendActiveMail',
        '&email=' + email +
        '&urlCallback=' + urlCallback +
        '&urlRedirect=' + urlRedirect
      )
    } catch (error) {
      return { success: false }
    }
  }

  async getUserInfo() {
    try {
      const response = await Oauth2API.internalOperations.get('/api/getInfoUser')

      return response.data
    } catch (error) {
      return { success: false }
    }
  }

  async getUserInfoByTokenBarba(tokenBarba, email) {
    try {
      const response = await Oauth2API.internalOperations.get(`/api/getInfoUserByTokenBarba?tokenBarba=${tokenBarba || ''}&email=${email || ''}`)

      return response.data
    } catch (error) {
      return { success: false }
    }
  }

  async changeOfPrimaryPhone(phone, accessToken) {
    try {
      const data = { phone }

      let options = {
        data,
        headers: { Authorization: `Bearer ${accessToken}` },
        method : 'put',
        url    : `${process.env.REACT_APP_ENTRY_SERVICE_AUTH}/api/changeofprimaryphone`
      }
      const response = await Axios(options)

      return response.data
    } catch (error) {
      return { success: false }
    }
  }

  async getUserInfoByToken(accessToken) {
    try {
      let options = {
        headers: { Authorization: `Bearer ${accessToken}` },
        method : 'get',
        url    : `${process.env.REACT_APP_ENTRY_SERVICE_AUTH}/api/getInfoUser`
      }

      const { data = {} } = await Axios(options)

      return data
    } catch (error) {
      return { success: false }
    }
  }

  async initSession(args) {
    try {
      const { email, password } = args

      const res = await Oauth2API.externalOperationsForm.post(
        '/api/oauth/token',
        'grant_type=password' +
        '&username=' +
        email.trim() +
        '&password=' +
        password +
        '&client_id=candidate' +
        '&client_secret=nuevo'
      )

      if(res.data.success)
        return {
          data: {
            accessToken : res.data.accessToken,
            refreshToken: res.data.refreshToken,
            userId      : res.data.userId
          },
          success: true
        }
      else
        return { data: '', success: false }
    } catch (error) {
      return { success: false }
    }
  }

  async loginRefreshToken(args) {
    try {
      return await Oauth2API.externalOperationsForm.post('/api/oauth/refreshtoken', args)
    } catch (error) {
      throw error
    }
  }

  async loginSocialNetwork(args, referrer = 'auth') {
    try {
      const { id_token, network } = args
      const response = await Oauth2API.externalOperationsForm.post(
        '/api/oauth/token',
        `grant_type=authorization_code&code=${network ||
        ''}-data-${referrer || ''}-krowdy-${id_token}&client_id=${this.clientid}&client_secret=${this.clientsecret}&social=${network || null}&socialId=${id_token}`
      )

      return response
    }
    catch (error) {
      throw error
    }
  }

  async loginSocialNetworkAndCheckIfRegister(args, referrer = 'auth') {
    try {
      const { id_token, network, emailUser } = args
      const  { data:{ success } = {} } = await this.verifyEmailExists({ email: emailUser })
      const response = await Oauth2API.externalOperationsForm.post(
        '/api/oauth/token',
        `grant_type=authorization_code&code=${network ||
        ''}-data-${referrer || ''}-krowdy-${id_token}&client_id=${this.clientid}&client_secret=${this.clientsecret}&social=${network || null}&socialId=${id_token}`
      )

      return { ...response, userRegistration: success }
    }
    catch (error) {
      throw error
    }
  }

  async loginWithInvitationCode({ invitationCode, network }, referrer = 'auth') {
    try {
      const queryStringForm = new URLSearchParams()

      queryStringForm.append('grant_type', 'authorization_code')
      queryStringForm.append('code', `${network}-data-${referrer}-krowdy-${invitationCode}`)
      queryStringForm.append('client_id', this.clientid)
      queryStringForm.append('client_secret', this.clientsecret)

      const response = await Oauth2API.externalOperationsForm.post(
        '/api/oauth/token',
        queryStringForm.toString()
      )

      return response
    }
    catch (error) {
      throw error
    }
  }

  async logout(args) {
    try {
      return await Oauth2API.externalOperationsJson(`/api/logout?${buildUrlUri(args)}`)
    } catch (error) {
      throw error
    }
  }

  async logoutMagneto(args) {
    try {
      return await Oauth2API.externalOperationsJson(`/api/logoutMagneto?${buildUrlUri(args)}`)
    } catch (error) {
      throw error
    }
  }

  async verifyEmail(args) {
    try {
      const { email = null } = args
      const data = { email: email }
      const { data: response } = await Oauth2API.externalOperationsJson.post('/api/signin/userbyemail', data)
      const { serviceContact } = response
      if(response && serviceContact)
        window.location.href = `${maintenanceService}${response.userId}`

      return response
      // return  { data: { active: res.data.active, email, userId: res.data.userId }, success: res.data.success }
    } catch (error) {
      throw error
    }
  }

  async createPasswordUserBarba(args) {
    try {
      const { password, tokenBarba } = args
      const data = { password, tokenBarba }
      const { data: response } = await Oauth2API.externalOperationsJson.post('/api/createPasswordUserBarba', data)

      return response
    } catch (error) {
      throw error
    }
  }

  async verifyEmailExists(args) {
    try {
      const { email = null } = args
      const data = { email: email }
      const res = await Oauth2API.externalOperationsJson.post('/validateRepeatEmail', data)

      return res
      // return  { data: { active: res.data.active, email, userId: res.data.userId }, success: res.data.success }
    } catch (error) {
      throw error
    }
  }
}

export default SIGNIN
