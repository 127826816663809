import React from 'react'
import { makeStyles } from '@krowdy/kds-styles'
import { withRouter } from 'react-router-dom'

const logoStyles = makeStyles(() => ({
  containLogo: {
    '@media screen and (max-height: 832px)': {
      padding: '12px 0'
    },
    display     : 'flex',
    marginBottom: 'calc((100vh - 600px) / 10)',
    // eslint-disable-next-line no-dupe-keys
    marginBottom: 'calc((var(--vh, 1vh) * 100  - 600px) / 10)',
    maxWidth    : 439,
    padding     : '4px 0',
    position    : 'relative',
    width       : '90%'
  },
  logo: {
    height  : 48,
    maxWidth: 100,
    top     : 30
  }
}))

const Logo = () => {
  const classes = logoStyles()

  return (
    <div className={classes.containLogo} >
      <img
        alt='Krowdy'
        className={classes.logo}
        src='https://cdn.krowdy.com/media/images/logo-krowdy-barba.svg' />
    </div>
  )
}

export default withRouter(Logo)
