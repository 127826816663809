import qs from 'querystring'
import Oauth2API from './Oauth2API'
import PortalesAPI from './PortalesAPI'

class BRANDING {
  async getTheme({
    slug,
    jobId
  }) {
    try {
      let dataTheme
      const { data } = await Oauth2API.externalOperationsJson.get(`/api/branding/theme?${qs.stringify({
        jobId,
        slug
      })}`)

      dataTheme = data
      if(slug && !dataTheme) {
        const { data: resp } = await PortalesAPI.externalOperationsJson.get(`/api/v1/laborexchange/theme/slug/${slug}`)

        dataTheme = {
          data: {
            ...resp,
            cssTextFamily: resp.cssTextFamily
          }
        }
      }

      return data?.data
    } catch (error) {
      return null
    }
  }
}

export default BRANDING
