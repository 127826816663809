const buildUrlUri = data => {
  const keys = Object.keys(data)

  return keys.map(key => `${key}=${data[key]}`).join('&')
}

const concatFullName = (firstName, lastName) => {
  if(!firstName &&  !lastName) return null
  if(firstName && lastName)
    return `${firstName} ${lastName}`
  else
    return firstName || lastName
}

const clean = (obj, exceptions = []) => {
  const tmp = { ...obj }
  const keys = Object.keys(obj)

  for (const key of keys)
    if((tmp[key] === null || tmp[key] === undefined) && !exceptions.map(ex => ex.toString()).includes(key))
      delete tmp[key]

  return tmp
}

export { buildUrlUri, concatFullName, clean }

