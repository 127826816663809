import mixpanel from 'mixpanel-browser'
import { decodeURIString } from '../../utils'
import { concatFullName, clean } from './utils'

mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN)

class MixPanelKrowdy {
  validateTrackEvent(urlRedirect) {
    try {
      let atsUrl = process.env.REACT_APP_ATS_URI
      if(decodeURIString(urlRedirect).includes(atsUrl))
        return true

      return false
    } catch (error) {
      console.log('🚀 ~ file: MixPanel.js ~ line 14 ~ MixPanelKrowdy ~ validateTrackEvent ~ error', error)
    }
  }
  process(urlRedirect, event, payload) {
    try {
      let trackEvent = this.validateTrackEvent(urlRedirect)

      if(!trackEvent) return
      switch (event) {
        case 'tryRecovery':
          return this.tryRecovery(urlRedirect)
        case 'tryToLogin':
          return this.tryToLogin(urlRedirect, payload)
        case 'tryRegister':
          return this.tryRegister(urlRedirect, payload)
        case 'registerUser':
          return this.registerUser(payload, urlRedirect)
        case 'loginUser':
          return this.loginUser(payload, urlRedirect)
        case 'validateUser':
          return this.validateUser(payload, urlRedirect)
        case 'sendRecovery':
          return this.sendRecovery(urlRedirect, payload)
        case 'openLinkRecovery':
          return this.openLinkRecovery(urlRedirect, payload)
        case 'sendChangePassword':
          return this.sendChangePassword(urlRedirect, payload)
        default:
          break
      }
    } catch (error) {
      console.log('🚀 ~ file: MixPanel.js ~ line 35 ~ MixPanelKrowdy ~ process ~ error', error)
    }
  }

  async openLinkRecovery(urlRedirect, payload) {
    try {
      const { firstName, lastName, email, userId } = payload
      mixpanel.track('Usuario abrio el link de restauracion de password', {
        Aplicacion           : 'auth',
        Email                : email,
        'ID de Usuario'      : userId,
        'Nombres y Apellidos': concatFullName(firstName, lastName),
        'URL Principal'      : window.location.origin,
        'URL de Redireccion' : urlRedirect
      })
    } catch (error) {
      console.log('🚀 ~ file: MixPanel.js ~ line 43 ~ MixPanelKrowdy ~ tryRegister ~ error', error)
    }
  }

  async sendRecovery(urlRedirect, payload) {
    const { contactValue, type } = payload
    try {
      const keyContact = type === 'email' ? 'Email' : 'Celular'
      mixpanel.track('Usuario envia solicitud de recuperacion de cuenta', {
        Aplicacion              : 'auth',
        'Metodo de Recuperación': type === 'email' ? 'Email' : 'Celular',
        'URL Principal'         : window.location.origin,
        'URL de Redireccion'    : urlRedirect,
        [keyContact]            : contactValue
      })
    } catch (error) {
      console.log('🚀 ~ file: MixPanel.js ~ line 43 ~ MixPanelKrowdy ~ tryRegister ~ error', error)
    }
  }

  async sendChangePassword(urlRedirect, payload) {
    try {
      const { firstName, lastName, email, userId } = payload
      mixpanel.track('Usuario realizo el cambio de password',  {
        Aplicacion           : 'auth',
        Email                : email,
        'ID de Usuario'      : userId,
        'Nombres y Apellidos': concatFullName(firstName, lastName),
        'URL Principal'      : window.location.origin,
        'URL de Redireccion' : urlRedirect
      })
    } catch (error) {
      console.log('🚀 ~ file: MixPanel.js ~ line 43 ~ MixPanelKrowdy ~ tryRegister ~ error', error)
    }
  }

  async tryRecovery(urlRedirect) {
    try {
      mixpanel.track('Usuario intenta recuperar su password', {
        Aplicacion          : 'auth',
        'URL Principal'     : window.location.origin,
        'URL de Redireccion': urlRedirect
      })
    } catch (error) {
      console.log('🚀 ~ file: MixPanel.js ~ line 43 ~ MixPanelKrowdy ~ tryRegister ~ error', error)
    }
  }

  async tryToLogin(urlRedirect, payload) {
    try {
      const { signupBarba, email, tokenBarba } = payload
      let dataToTrack = {
        Aplicacion          : 'auth',
        'Proyecto Barba'    : Boolean(signupBarba),
        'URL Principal'     : window.location.origin,
        'URL de Redireccion': urlRedirect
      }

      if(signupBarba) {
        dataToTrack.email = email
        dataToTrack.tokenBarba = tokenBarba
      }
      mixpanel.track('Usuario ingresa a la pagina de login', dataToTrack)
    } catch (error) {
      console.log('🚀 ~ file: MixPanel.js ~ line 43 ~ MixPanelKrowdy ~ tryRegister ~ error', error)
    }
  }

  async tryRegister(urlRedirect, payload) {
    try {
      const { signupBarba } = payload
      mixpanel.track('Usuario ingresa a la pagina de registro', {
        Aplicacion          : 'auth',
        'Proyecto Barba'    : Boolean(signupBarba),
        'URL Principal'     : window.location.origin,
        'URL de Redireccion': urlRedirect
      })
    } catch (error) {
      console.log('🚀 ~ file: MixPanel.js ~ line 43 ~ MixPanelKrowdy ~ tryRegister ~ error', error)
    }
  }

  async processNoAuth(urlRedirect, event) {
    try {
      let trackEvent = this.validateTrackEvent(urlRedirect)

      if(!trackEvent) return
      switch (event) {
        case 'userSeekAccess':
          return this.userSeekAccess(urlRedirect)
        default:
          break
      }
    } catch (error) {
      console.log('🚀 ~ file: MixPanel.js ~ line 63 ~ MixPanelKrowdy ~ processNoAuth ~ error', error)
    }
  }

  async resetIdentify() {
    try {
      mixpanel.reset()
    } catch (error) {
      console.log('🚀 ~ file: MixPanel.js ~ line 71 ~ MixPanelKrowdy ~ resetIdentify ~ error', error)
    }
  }

  async userSeekAccess(urlRedirect) {
    try {
      await mixpanel.track('User Seek Enter App', {
        Aplicacion          : 'auth',
        'URL Principal'     : window.location.origin,
        'URL de Redireccion': urlRedirect
      })
    } catch (error) {
      console.log('🚀 ~ file: MixPanel.js ~ line 83 ~ MixPanelKrowdy ~ userSeekAccess ~ error', error)
    }
  }

  async registerUser(payload, urlRedirect) {
    try {
      const { email, firstName, lastName, phone, userId, type, signupBarba } = payload

      await mixpanel.identify(userId)
      mixpanel.register({
        Apellido             : lastName,
        Celular              : phone,
        Email                : email,
        Nombre               : firstName,
        'Nombres y Apellidos': concatFullName(firstName, lastName),
        'Proyecto Barba'     : Boolean(signupBarba)
      })

      mixpanel.track('Registro de usuario', {
        Aplicacion          : 'auth',
        Celular             : phone,
        Email               : email,
        'Es equipo krowdy'  : false,
        'Metodo de registro': type,
        'Proyecto Barba'    : Boolean(signupBarba),
        'URL de Redireccion': urlRedirect
      })

      mixpanel.people.set({
        $email             : email,
        $phone             : phone,
        'Es equipo krowdy' : false,
        'Fecha de Registro': Date.now(),
        'Proyecto Barba'   : Boolean(signupBarba),
        name               : concatFullName(firstName, lastName)
      })
    } catch (error) {
      console.log('🚀 ~ file: MixPanel.js ~ line 118 ~ MixPanelKrowdy ~ registerUser ~ error', error)
    }
  }

  async loginUser(payload, urlRedirect) {
    try {
      const { userId, type, email, lastName, firstName, phone, view } = payload
      await mixpanel.identify(userId)
      const superProperties = clean({
        Apellido             : lastName,
        Celular              : phone,
        Email                : email,
        Nombre               : firstName,
        'Nombres y Apellidos': concatFullName(firstName, lastName)
      })

      mixpanel.register(superProperties)

      if(email)
        mixpanel.track('Inicio de sesion', {
          Aplicacion          : 'auth',
          Email               : email,
          'Fecha de conexion' : Date.now(),
          'Metodo de ingreso' : type,
          'URL de Redireccion': urlRedirect,
          'Vista de accion'   : view || null
        })
      else
        mixpanel.track('Inicio de sesion', {
          Aplicacion          : 'auth',
          'Fecha de conexion' : Date.now(),
          'Metodo de ingreso' : type,
          'URL de Redireccion': urlRedirect,
          'Vista de accion'   : view || null

        })
      const profileProperties = clean({
        $email: email,
        $phone: phone,
        name  : concatFullName(firstName, lastName)
      })

      mixpanel.people.set(profileProperties)
    } catch (error) {
      console.log('🚀 ~ file: MixPanel.js ~ line 133 ~ MixPanelKrowdy ~ loginUser ~ error', error)
    }
  }

  async logoutUser(payload, urlRedirect) {
    try {
      const { userId } = payload
      await mixpanel.identify(userId)

      mixpanel.track('Cierre de sesion', {
        Aplicacion            : 'auth',
        'Fecha de desconexion': Date.now(),
        'URL de Redireccion'  : urlRedirect
      })

      mixpanel.reset()
    } catch (error) {
      console.log('🚀 ~ file: MixPanel.js ~ line 148 ~ MixPanelKrowdy ~ logoutUser ~ error', error)
    }
  }

  async validateUser(payload, urlRedirect) {
    try {
      const { firstName, lastName, userId, email } = payload
      await mixpanel.identify(userId)
      mixpanel.track('Activacion de cuenta', {
        Aplicacion           : 'auth',
        Email                : email,
        'Nombres y Apellidos': concatFullName(firstName, lastName),
        'URL de Redireccion' : urlRedirect
      })
      mixpanel.people.set({
        'Fecha de validacion email': Date.now()
      })
    } catch (error) {
      console.log('🚀 ~ file: MixPanel.js ~ line 165 ~ MixPanelKrowdy ~ validateUser ~ error', error)
    }
  }
}

const mixPanelEvent = new MixPanelKrowdy()
export {
  mixPanelEvent
}
