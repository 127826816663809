import urlEncode from 'urlencode'

const firstLetterUpper = (string) => {
  if(string)
    return string.split(' ').map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(' ')

  return string
}

const decodeURIString = (text) => {
  try {
    if(!text) return text

    const decodeString = decodeURIComponent(text)

    return decodeString
  } catch (error) {
    console.log('decodeURIString ~ error', error)

    return text
  }
}

export {
  firstLetterUpper,
  decodeURIString
}

export const paletteOneTap = {
  'default': '#ff192b',
  krowdy   : '#FF8B08',
  laborum  : '#ff192b'
}

export const getCredentials = () => {
  const keys = [ 'accessToken', 'refreshToken', 'iduser' ]
  let credentials = {}
  for (const key of keys) {
    const value = localStorage.getItem(key)
    credentials[key] = value
  }

  return credentials
}

export const createQueryString = (data) => Object.keys(data).map(key => {
  let val = data[key]
  if(val !== null && typeof val === 'object') val = createQueryString(val)

  const encode = typeof window !== 'undefined' ? window.encodeURIComponent : urlEncode

  return `${key}=${encode(`${val}`.replace(/\s/g, '_'))}`
}).join('&')

const externalDomainClients = [
  {
    host              : 'bolsadetrabajo.anidigraf.com',
    slugExternalClient: 'anidigraf'
  },
  {
    host              : 'talento.pucmm.edu.do',
    slugExternalClient: 'pucmm'
  },
  {
    host              : 'bolsadetrabajo.isur.edu.pe',
    slugExternalClient: 'isur'
  },
  {
    host              : 'bolsadetrabajo.cientifica.edu.pe',
    slugExternalClient: 'cientifica'
  },
  {
    host              : 'bolsadetrabajo.sise.edu.pe',
    slugExternalClient: 'institutosise'
  }
]

export const getConfigFromExternalDomainClients = (hostRedirect) => {
  try {
    if(!hostRedirect)
      return null
    const externalClient = externalDomainClients.find(client => hostRedirect?.includes(client?.host))

    return externalClient
  } catch (error) {
    return null
  }
}
