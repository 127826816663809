const { REACT_APP_CDN_LINK } = process.env

const IMAGES = {
  general: {
    logoBase : 'logobase.png',
    logoWhite: '',
    social   : {
      facebook: 'iconFb.svg',
      google  : 'iconGoogle.svg',
      linkedin: 'iconLink.svg',
      twitter : 'twitter.png'
    }
  },
  presentationView: {
    login   : 'login.svg',
    recovery: 'restablecer.svg',
    register: {
      view1: 'register.svg',
      view2: 'bienvenido.svg',
      view3: ''
    }
  }
}

const source = (key) =>{
  return `${REACT_APP_CDN_LINK}/${key}`
}

export {
  IMAGES,
  source
}
