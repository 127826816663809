import React from 'react'

import { makeStyles } from '@krowdy/kds-styles'

export default function Loading(props) {
  const classes = useStyles()

  return (
    <div className={classes.contentLoading} {...props}>
      <img
        alt={'loadingKrowdy'}
        height={150}
        src={
          'https://cdn.krowdy.com/images/loader.gif'
        } />
    </div>
  )
}

const useStyles = makeStyles({
  contentLoading: {
    alignItems    : 'center',
    background    : '#f0f2f5',
    color         : 'rgba(0,0,0,.1)',
    display       : 'flex',
    fontSize      : '50px',
    height        : '100vh',
    // eslint-disable-next-line no-dupe-keys
    height        : 'calc(var(--vh, 1vh) * 100)',
    justifyContent: 'center',
    textAlign     : 'center'
  }
})
