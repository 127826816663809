import React from 'react'
import { useTheme, Grid, Box } from '@krowdy/kds-core'

import { SIGNIN } from '../../datalayer/Accounts'
import Loading from '../../components/Loading'
import QueryString from 'query-string'
import Krowdy from './krowdy'
import BarbaAccount from './barbaAccount'
import ImgAccount from './ImgAccount'
import { makeStyles } from '@krowdy/kds-styles'
import { mixPanelEvent } from '../../datalayer/Accounts/MixPanel'
import { buildUrlUri } from '../../utils/session/utils'

const signin = new SIGNIN()
const REACT_APP_MYACCOUNT = process.env.REACT_APP_USER_ACCOUNT
const clientId = process.env.REACT_APP_CLIENT_ID
const clientSecret = process.env.REACT_APP_CLIENT_SECRET
const grantType = 'refresh_token'

/** dont remove this eslint-disable */
/* eslint-disable */
function Account({ children }) {
  const [isAuthenticated, setAuthenticated] = React.useState('NEW')
  const classes = useStyles()
  const { template: { auth: { background } = {} } } = useTheme()
  const { urlCallback, urlRedirect } = QueryString.parse(window.location.search)
  const pathBarba = window.location.pathname
  React.useEffect(() => {
    if (isAuthenticated !== 'READY')
      authenticate()
  }, [isAuthenticated])

  const authenticate = async () => {
    try {
      const internalAccessToken = window.localStorage.getItem('accessToken')
      const internalRefreshToken = window.localStorage.getItem('refreshToken')
      if (!internalAccessToken) {
        localStorage.clear()
        mixPanelEvent.resetIdentify()
        setAuthenticated('NOT_READY')
      }

      const response = await signin.authenticate()

      const { data: { success = null } = {} } = response

      if (!success) {
        if (!internalRefreshToken) {
          return setAuthenticated('NOT_READY')
        }

        const args = buildUrlUri({
          client_id: clientId,
          client_secret: clientSecret,
          grant_type: grantType,
          refresh_token: internalRefreshToken
        })
        const response = await signin.loginRefreshToken(args)
        const {
          data: {
            refreshToken: refreshTokenFromRefresh = null,
            accessToken: accessTokenFromRefresh = null,
            userId,
            error
          } } = response
        if (error) {
          return setAuthenticated('NOT_READY')
        }
        localStorage.clear()
        localStorage.setItem('refreshToken', refreshTokenFromRefresh)
        localStorage.setItem('accessToken', accessTokenFromRefresh)
        localStorage.setItem('iduser', userId)
        return window.location.href = window.location.href
      } else {
        setAuthenticated('READY')
        const refreshToken = localStorage.getItem('refreshToken')
        const iduser = localStorage.getItem('iduser')
        const access = `refreshToken=${refreshToken}&accessToken=${internalAccessToken}&iduser=${iduser}&timestamp=${new Date().getTime()}`
        if (!(urlCallback && urlRedirect)) {
          if (pathBarba && pathBarba.includes('signupbarba')) {
            await mixPanelEvent.process(urlRedirect, 'loginUser', {
              type: 'Middleware Auth Success Token',
              userId: iduser,
              view: 'Login Middleware'

            })
            return window.location.href = `${process.env.REACT_APP_ATS_URI}/callback?urlRedirect=${process.env.REACT_APP_ATS_URI}&${access}`
          }
          return window.location.href = `${REACT_APP_MYACCOUNT}/callback?urlRedirect=${REACT_APP_MYACCOUNT}&${access}`
        }
        window.location.href = `${urlCallback}?urlRedirect=${urlRedirect}&${access}`
      }
    } catch (error) {
      console.log("🚀 ~ authenticate ~ error", error)
      localStorage.clear()
      return setAuthenticated('NOT_READY')
    }
  }

  const style = background ? {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  } : {}

  return (
    <React.Fragment>
      {
        isAuthenticated === 'NOT_READY' ?
          pathBarba.includes('signinbarba') || pathBarba.includes('signupbarba') ||
            pathBarba.includes('welcomebarba') || pathBarba.includes('validatebarba') || pathBarba.includes('passwordrecoverybarba') ?
            <BarbaAccount
              bannerType={
                pathBarba.includes('signinbarbalaborum') ? 'video-publication' :
                  pathBarba.includes('signinbarba') ? 'video-candidates' : 'image'}
              view={children}>
            </BarbaAccount> :
            <Grid container className={classes.root} style={style}>
              <Grid
                item
                lg={4}
                style={{ display: 'flex', height: '100%', padding: 20 }}
                xs={12}>
                <Krowdy view={children}></Krowdy>
              </Grid>
              <Grid
                item
                lg={8}
                style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', width: '100%' }}
                xs={12}>
                {
                  pathBarba === 'signupbarba' || urlRedirect && urlRedirect.includes(process.env.REACT_APP_ATS_URI) ?
                    <Box display={{ xs: 'none', lg: 'block' }} style={{ width: '100%', height: '100%' }} >
                      {!background ? <ImgAccount /> : null}
                    </Box>
                    : !background ? <ImgAccount /> : null
                }

              </Grid>
            </Grid>
          : <Loading />
      }
    </React.Fragment>
  )
}

const useStyles = makeStyles(({
  root: {
    height: '100vh',
    height: 'calc(var(--vh, 1vh) * 100)',
    overflow: 'hidden'
  }
}))

export default Account
