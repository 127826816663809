import React from 'react'
import { makeStyles } from '@krowdy/kds-core/styles'
import { Typography } from '@krowdy/kds-core'
import clsx from 'clsx'
const illustration = 'https://cdn.krowdy.com/media/images/MacbookProTilted.svg'

const BannerAuth = () => {
  const classes = useStyles()

  return (
    <div className={classes.largeMarginLeft}>
      <div className={clsx(classes.containerText, classes.secondary900, classes.marginTopLarge)}>
        <Typography
          align='center'
          className={classes.body1}
          variant='h3'> <strong> ¿Qué es Krowdy?</strong> </Typography>
        <Typography
          align='center'
          className={classes.body1}
          variant='h3'>Plataforma gratuita de reclutamiento digital</Typography>
        <ul className={classes.listText}>
          <li>
                  Publica gratis en Laborum y en redes sociales
          </li>
          <li>
                  Realiza múltiples evaluaciones a los candidatos
          </li>
          <li>
                  Sigue todos tus procesos de manera digital y automática
          </li>
        </ul>

      </div>
      <img alt='illustration' className={classes.illustration} src={illustration} />
    </div>

  )
}

const useStyles = makeStyles(theme => ({
  body1: {
    fontSize  : 20,
    fontWeight: 'normal',
    lineHeight: '30px',
    textAlign : 'left'
  },
  containerText: {
    fontWeight : '500',
    maxWidth   : 620,
    paddingLeft: theme.spacing(5.5),
    textAlign  : 'left'
  },
  illustration: {
    maxWidth                    : 800,
    [theme.breakpoints.up(1460)]: {
      maxWidth: 1000
    }
  },
  largeMarginLeft: {
    marginLeft: theme.spacing(2.5)
  },
  listText: {
    fontSize  : 14,
    fontWeight: 'normal',
    lineHeight: '26px'
  },
  marginTopBig: {
    marginTop: theme.spacing(6.5)
  },
  marginTopLarge: {
    marginTop: theme.spacing(4.5)
  },
  secondary900: {
    color: '#00173D'
  },
  video: {
    maxWidth                    : 600,
    [theme.breakpoints.up(1460)]: {
      maxWidth: 760
    }
  }

}))

export default BannerAuth
