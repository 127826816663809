import Oauth2API from './Oauth2API'

class VALIDATORS {
  async ValidateTokenbyId(args) {
    const { id, token, tokenBarba = null } = args
    try {
      return Oauth2API.externalOperationsJson.post('/api/validateAccountWithToken', { id, token, tokenBarba })
    } catch (error) {
      throw error
    }
  }

  async validateAnotherEmail(args) {
    try {
      return Oauth2API.externalOperationsFormWithAuth.put('/api/confirmemail', args)
    } catch (error) {
      throw error
    }
  }
}
export default VALIDATORS
