import { useState } from 'react'
import { createTheme, krowdyTheme } from '@krowdy/kds-core'
import BRANDING from '../datalayer/Accounts/BRANDING'

const theme = createTheme({
  ...krowdyTheme,
  palette: {
    ...krowdyTheme.palette,
    primary: {
      ...krowdyTheme.palette.primary,
      contrastText: 'rgba(255, 255, 255, 0.87)'
    }
  },
  template: {
    header: {
      logo: {
        source: '//cdn.krowdy.com/media/images/KrowdyLogo3.svg'
      }
    }
  }
})

const brandingServices = new BRANDING()

export const useGetThemeByDomain = () => {
  const [ loading, setLoading ] = useState(true)
  const [ newTheme, setTheme ] = useState(theme)

  const getTheme = async ({ slug, jobId }) => {
    try {
      if(slug || jobId) {
        const theme = await brandingServices.getTheme({
          jobId,
          slug
        })

        if(!theme) return

        delete theme.overrides
        delete theme.typography

        setTheme(prev => ({
          ...prev,
          ...theme,
          palette: {
            ...prev.palette,
            ...theme.palette
          }
        }))
      }
    } catch {
    } finally {
      setLoading(false)
    }
  }

  return [ getTheme, { loading, theme: createTheme(newTheme) } ]
}
