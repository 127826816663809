import axios from 'axios'

const { REACT_APP_ENTRY_SERVICE_AUTH } = process.env

const Oauth2API = {
  externalOperationsForm: axios.create({
    baseURL: REACT_APP_ENTRY_SERVICE_AUTH,
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    }
  }),
  externalOperationsFormWithAuth: axios.create({
    baseURL: REACT_APP_ENTRY_SERVICE_AUTH,
    headers: {
      Authorization : 'Bearer ' + window.localStorage.getItem('accessToken') || null,
      'content-type': 'application/x-www-form-urlencoded'
    }
  }),
  externalOperationsJson: axios.create({
    baseURL: REACT_APP_ENTRY_SERVICE_AUTH,
    headers: {
      Authorization : 'Bearer ' + window.localStorage.getItem('accessToken') || null,
      'content-type': 'application/json'

    }
  }),
  internalOperations: axios.create({
    baseURL: REACT_APP_ENTRY_SERVICE_AUTH,
    headers: {
      Authorization : 'Bearer ' + window.localStorage.getItem('accessToken'),
      'content-type': 'application/json'
    },
    timeout: 3000
  })
}

export default Oauth2API
