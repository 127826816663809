import Oauth2API from './Oauth2API'
class RECOVERY {
  async email(args) {
    try {
      const {
        email,
        urlCallback,
        urlRedirect,
        typeView
      } = args

      return Oauth2API.externalOperationsJson.post('/api/signin/newsendemailchangepassword', { email, typeView, urlCallback, urlRedirect })
    } catch (error) {
      throw error
    }
  }
  async phone(args) {
    try {
      const { phone } = args

      return Oauth2API.externalOperationsJson.post('/api/signin/newsmsremember', { phone })
    } catch (error) {
      throw error
    }
  }

  async verifyCode(args) {
    try {
      const { phone, code_veri } = args
      const data = `code=${code_veri}&phone=${phone}`
      const rest = await Oauth2API.externalOperationsForm.post('/api/signin/verifycode', data)

      return { ...rest }
    } catch (error) {
      return { success: false }
    }
  }
  async changePassword(args) {
    const { idUser, rememberToken, password } = args
    try {
      return Oauth2API
        .externalOperationsForm
        .put('/api/signin/newchangepassword',
          `idUser=${idUser}&password=${password}&rememberToken=${rememberToken}`)
    } catch (error) {
      throw error
    }
  }

  async verifyRememberToken(args) {
    try {
      // Llamar mas datos
      const { rememberToken, idUser } = args
      const data = `rememberToken=${rememberToken}&idUser=${idUser}`
      const rest = await Oauth2API.externalOperationsForm.put('/api/signin/verifyRememberToken', data)

      return rest
    } catch (error) {
      console.log(error.message)
    }
  }

  async getEmailsByPhone(args) {
    if(args.phone)
      return await Oauth2API
        .externalOperationsForm
        .post('/api/signin/getemailsbyphone',
          `phone=${args.phone}`)
    else
      return {
        data   : 'parameter received not found',
        success: false
      }
  }
  async validateBeforeMerge(args) {
    try {
      const {
        email,
        idCheckCode,
        phone
      } = args
      if(!email || !idCheckCode || !phone) return false

      return await Oauth2API
        .externalOperationsForm
        .post('/api/signin/validatebeforemerge',
          `email=${email}&idCheckCode=${idCheckCode}&phone=${phone}`)
    } catch (error) {
      console.log('Error: ', error.message)

      return { data: error.message, success: false }
    }
  }
  async mergeAccounts(args) {
    try {
      const {
        _id,
        idCheckCode,
        urlCallback,
        urlRedirect
      } = args

      return await Oauth2API
        .externalOperationsForm
        .post('/api/signin/mergeAccounts',
          `_id=${_id}&idCheckCode=${idCheckCode}&urlCallback=${urlCallback}&urlRedirect=${urlRedirect}`)
    } catch (error) {
      console.log('Error: ', error.message)

      return { data: error.message, success: false }
    }
  }
}

export default RECOVERY
