import Oauth2API from './Oauth2API'

const maintenanceService = 'https://cdn.krowdy.com/auth/service/index.html?userId='
class SIGNUP {
  async register(args) {
    try {
      return await Oauth2API.externalOperationsJson.post('/api/signin/createaccount', args)
    } catch (error) {
      throw error
    }
  }

  async registerOneTab(args) {
    try {
      return await Oauth2API.externalOperationsJson.post('/api/signin/createaccount/onetabsync', args)
    } catch (error) {
      throw error
    }
  }

  async verifyOneTab(args) {
    try {
      const { code = null, userId = null } = args
      const data = { code, userId }

      return await Oauth2API.externalOperationsJson.post('/api/signin/verifycode/onetap', data)
    } catch (error) {
      throw error
    }
  }

  async verifyEmail(args) {
    try {
      const { email = null } = args
      const data = { email: email }
      const { data: response } = await Oauth2API.externalOperationsJson.post('/api/signin/userbyemail', data)
      const { serviceContact } = response
      if(response && serviceContact)
        window.location.href = `${maintenanceService}${response.userId}`

      return response
    } catch (error) {
      throw error
    }
  }
}

export default SIGNUP
