import axios from 'axios'

const { REACT_APP_API_BOLSAS } = process.env

const PortalesAPI = {
  externalOperationsJson: axios.create({
    baseURL: REACT_APP_API_BOLSAS,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export default PortalesAPI
