import React, { useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import qs from 'query-string'
import { AuthContext } from '@krowdy/kds-auth'
import { ThemeProvider, makeStyles, Dialog, DialogContent, Avatar, Link, Typography } from '@krowdy/kds-core'
import { Error as ErrorIcon } from '@material-ui/icons'

import * as serviceWorker from './serviceWorker'
import Root from './router'
import './index.css'
import Loading from './components/Loading'
import { useGetThemeByDomain } from './hooks/theme'
import { getConfigFromExternalDomainClients } from './utils'


const bolsasAppName = process.env.REACT_APP_BOLSAS_APPNAME || 'localhost'

const ErrorModal = () => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.dialogTitle}>
        <Avatar className={classes.avatar}>
          <ErrorIcon color='primary' />
        </Avatar>
        <Typography className={classes.title} component='h2' variant='h5'>
          Certificados vencidos en Windows7
        </Typography>
      </div>
      <DialogContent>
        <Typography component='p' variant='body1'>
          La versión de tu sistema operativo no cuenta con certificados actualizados. Para solucionarlo, sigue las{' '}
          <Link href={'https://drive.google.com/file/d/1FFa12VSmP4sI7F31uYyiyRXhMzzL4Qhr/view?usp=sharing'}>instrucciones aquí.</Link>
        </Typography>
      </DialogContent>
    </>
  )
}

const App = () => {
  const [ getTheme, { loading, theme } ] = useGetThemeByDomain()
  const classes = useStyles()

  const [ isOpenModal, setIsOpenModal ] = useState(false)

  const params = qs.parse(window.location.search)
  // const { urlRedirect } = params

  const urlRedirect = useMemo(() => {
    if(!Array.isArray(params.urlRedirect)) return params.urlRedirect

    return params.urlRedirect[1] || params.urlRedirect[0]
  }, [ params.urlRedirect ])

  const [ , , slug, urlCore = '' ] = urlRedirect ? urlRedirect.split(new RegExp('[ / | . ]')) : []
  let isFromBolsasUniversitarias = urlCore.indexOf(`${bolsasAppName}`) !== -1

  let slugValue = slug
  if(urlRedirect && urlRedirect !== null && urlRedirect.toString().includes('.edu.')) {
    isFromBolsasUniversitarias = true
    slugValue = urlCore
  }

  let externalDomainConfig = getConfigFromExternalDomainClients(urlRedirect)
  if(externalDomainConfig) {
    isFromBolsasUniversitarias = true
    slugValue = externalDomainConfig?.slugExternalClient
  }
  
  const toggleErrorModal = (value) => () => {
    setIsOpenModal(value)
  }

  useEffect(() => {
    getTheme({
      jobId: params.jobId,
      slug : isFromBolsasUniversitarias ? slugValue : null
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const _handleError = (error/* typeView */) => {
    if(error === 'unable to verify the first certificate') setIsOpenModal(true)
  }

  if(loading) return <Loading />

  return (
    <ThemeProvider theme={theme}>
      <AuthContext
        baseUrl={process.env.REACT_APP_ENTRY_SERVICE_AUTH}
        clientId={process.env.REACT_APP_CLIENT_ID}
        clientSecret={process.env.REACT_APP_CLIENT_SECRET}
        customLegalBase={{
          footerHTML: theme.template.legalBase
        }}
        isBolsa={isFromBolsasUniversitarias}
        onError={_handleError}
        referrer='auth'
        social={{
          google: {
            clientId   : process.env.REACT_APP_KEY_SERVICE_GOOGLE,
            redirectUri: `${window.location.origin}/login`
          },
          linkedin: {
            clientId   : process.env.REACT_APP_KEY_SERVICE_LINKEDIN,
            redirectUri: `${window.location.origin}/login`
          },
          microsoft: {
            clientId   : process.env.REACT_APP_KEY_SERVICE_MICROSOFT,
            redirectUri: `${window.location.origin}/login`
          }
        }}>
        <Helmet>
          <meta content={theme.palette.primary.main} name='theme-color' />
        </Helmet>
        <Root />
        <Dialog
          onClose={toggleErrorModal(false)}
          open={isOpenModal}
          PaperProps={{
            classes: {
              root: classes.errorModal
            }
          }}>
          <ErrorModal />
        </Dialog>
      </AuthContext>
    </ThemeProvider>
  )
}

const useStyles = makeStyles(
  (theme) => ({
    avatar: {
      backgroundColor: theme.palette.secondary[10],
      marginBottom   : theme.spacing(1)
    },
    dialogContent: {
      padding: theme.spacing(0)
    },
    dialogTitle: {
      alignItems     : 'center',
      backgroundColor: theme.palette.secondary.F,
      display        : 'flex',
      flexDirection  : 'column',
      padding        : theme.spacing(2.5)
    },
    errorModal: {
      height: '300px',
      width : '400px'
    },
    title: {
      color: theme.palette.primary[900]
    }
  }),
  { name: 'ErrorModal' }
)

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
