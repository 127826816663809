import React from 'react'
import querySearch from 'stringquery'
import { makeStyles, Typography } from '@krowdy/kds-core'
import { IMAGES, source } from '../../../components/media'

const parseQuery = (queryString) => {
  try {
    const query = {}
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
    for (var i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=')
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
    }

    return query
  } catch (error) {
    return {}
  }
}

const ImgAccount = () => {
  const classes = useStyles()
  const params = querySearch(window.location.search)
  const { urlRedirect: urlRedirectDecode  = '' } = parseQuery(window.location.search)
  const pathBarba = window.location.pathname

  try {
    if(params && params.urlRedirect)
      if(
        params.urlRedirect.includes(process.env.REACT_APP_ATS_URI) ||
       pathBarba === '/signupbarba' ||
       pathBarba === '/signinbarba' ||
       urlRedirectDecode.includes(process.env.REACT_APP_ATS_URI)
      ) {
        const hexagono = 'https://cdn.krowdy.com/auth/ats/Hexagono+Azul.svg'
        const illustration = 'https://cdn.krowdy.com/auth/ats/Ilustracion.png'
        const background = 'https://cdn.krowdy.com/auth/ats/Fondo+Naranja.svg'

        return (
          <div className={classes.containerRightSect}>
            <img alt='hexagono_krowdy' className={classes.hexagono} src={hexagono} />
            <div style={{ maxWidth: 620, textAlign: 'center' }}>
              <Typography
                align='center'
                className={classes.title} color='primary' display='inline'
                variant='h3'>Krowdy: </Typography>
              <Typography
                align='center'
                className={classes.title}
                color='primary' display='inline'
                variant='h3'>software gratuito de reclutamiento digital</Typography>
            </div>
            <img alt='illustration' className={classes.illustration} src={illustration} />
            <div className={classes.bottomSection}>
              <Typography
                align='center' className={classes.subtitle} color='secondary'
                variant='h4'>¿Qué te ofrece Krowdy?</Typography>
              <Typography align='center' className={classes.text} variant='body3' >Atrae y gestiona al mejor talento en una sola plataforma,
             con la ayuda de la tecnología más avanzada, bases de talentos colaborativas y una red de
             profesionales de reclutamiento a tu disposición.</Typography>
            </div>
            <div style={{ bottom: 0, height: '68.8%', position: 'absolute', width: '100%', zIndex: -1 }}>
              <img alt='background_krowdy'  src={background} width='100%'  />
            </div>
          </div>
        )
      }
  } catch (error) {
    if(pathBarba === '/signupbarba')
      window.location.href = `/signupbarba?urlRedirect=${process.env.REACT_APP_ATS_URI}&urlCallback=${process.env.REACT_APP_ATS_URI}/callback`
  }

  const path = window.location.pathname.split('/')[1]
  switch (path) {
    case 'login':
      return <img alt='loginImage' className={classes.containerSize} src={source(IMAGES.presentationView.login)} />
    case 'verify':
      return <img alt='recoveryImage' className={classes.containerSize} src={source(IMAGES.presentationView.recovery)} />
    case 'signup':
      return <img alt='registerImage' className={classes.containerSize} src={source(IMAGES.presentationView.register.view1)}  />
    case 'welcome':
      return <img alt='registerImage' className={classes.containerSize} src={source(IMAGES.presentationView.register.view1)} />
    case 'usernamerecovery':
      return <img alt='recoveryImage' className={classes.containerSize} src={source(IMAGES.presentationView.recovery)} />
    default:
      return <img alt='loginImage' className={classes.containerSize} src={source(IMAGES.presentationView.login)} />
  }
}

const useStyles = makeStyles((theme) => ({
  bottomSection: {
    bottom                      : 24,
    maxWidth                    : 680,
    position                    : 'absolute',
    [theme.breakpoints.up(1440)]: {
      bottom: 60
    },
    [theme.breakpoints.up(1460)]: {
      bottom: 36
    },
    [theme.breakpoints.up(1600)]: {
      bottom: 48
    },
    [theme.breakpoints.up(1700)]: {
      bottom: 85
    }
  },
  containerRightSect: {
    alignContent  : 'center',
    alignItems    : 'center',
    display       : 'flex',
    flexDirection : 'column',
    height        : '100%',
    justifyContent: 'center',
    position      : 'relative',
    width         : '100%'
  },
  containerSize: {
    display  : 'block',
    maxHeight: '100vh'
  },
  hexagono: {
    left    : 0,
    position: 'absolute',
    top     : 100
  },
  illustration: {
    maxWidth                    : 800,
    [theme.breakpoints.up(1460)]: {
      maxWidth: 1000
    }
  },
  subtitle: {
    [theme.breakpoints.down(1460)]: {
      fontSize: 24
    }
  },
  text: {
    fontSize                      : 21,
    [theme.breakpoints.down(1460)]: {
      fontSize: 18
    }
  },
  title: {
    [theme.breakpoints.down(1460)]: {
      fontSize: 30
    }
  }
}), { name: 'ImgAccount' })

export default ImgAccount
