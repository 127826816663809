import React from 'react'
import { makeStyles } from '@krowdy/kds-styles'
import { useTheme } from '@krowdy/kds-core'
import { Link, withRouter } from 'react-router-dom'

const logoStyles = makeStyles(() => ({
  containLogo: {
    '@media screen and (max-height: 832px)': {
      padding: '12px 0'
    },
    display     : 'flex',
    marginBottom: 'calc((100vh - 600px) / 10)',
    // eslint-disable-next-line no-dupe-keys
    marginBottom: 'calc((var(--vh, 1vh) * 100  - 600px) / 10)',
    maxWidth    : 439,
    padding     : '4px 0',
    position    : 'relative',
    width       : '90%'
  },
  logo: {
    '@media screen and (max-height: 832px)': {
      height: 24,
      top   : 30
    },
    maxWidth: 100
  }
}))

const Logo = (props) => {
  const classes = logoStyles()
  const theme = useTheme()
  const getLogo = () => {
    const { template: { header: { logo: { source } } } } = theme

    return source
  }

  return (
    <div className={classes.containLogo} >
      <Link to={`/login${props.location.search}`}>
        <img
          alt='Krowdy'
          className={classes.logo}
          src={getLogo()} />
      </Link>
    </div>
  )
}

export default withRouter(Logo)
