import React from 'react'
import { makeStyles } from '@krowdy/kds-styles'

const legalBaseStyles = makeStyles(() => ({
  legalbase: {
    '@media screen and (max-height: 832px)': {
      bottom  : 0,
      margin  : '15px 0 0',
      position: 'relative'
    },
    '@media screen and (max-width: 1280px)': {
      bottom  : 0,
      margin  : '15px 0 0',
      position: 'relative'
    },
    bottom  : 32,
    maxWidth: '90%',
    position: 'absolute'
  }
}))
const Legalbase = () => {
  const classes = legalBaseStyles()

  return (
    <>
      <span
        className={classes.legalbase}
        style={{
          color     : '#8C8C8C',
          fontFamily: 'Roboto',
          fontSize  : 12
        }}>Al usar Krowdy aceptas los
        <a
          href='https://cdn.laborum.pe/public/laborum-condiciones-generales-de-uso.pdf?_ga=2.123654923.1496876818.1537374997-1836386932.1537374997'
          rel='noopener noreferrer'
          style={{
            color         : '#8C8C8C',
            fontWeight    : 'bold',
            textDecoration: 'none'
          }}
          target='_blank'> Términos y condiciones
        </a> y su
        <a
          href='https://cdn.laborum.pe/public/laborum-politica-de-proteccion-de-datos-y-privacidad.pdf'
          rel='noopener noreferrer'
          style={{
            color         : '#8C8C8C',
            fontWeight    : 'bold',
            textDecoration: 'none'
          }}
          target='_blank'> Política de privacidad
        </a>.
      </span>
    </>
  )
}
export default Legalbase
