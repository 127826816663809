import React/* , { useContext }  */ from 'react'
import { makeStyles } from '@krowdy/kds-core/styles'

import Paper from '@krowdy/kds-core/Paper'
import Legalbase from './LegalBase'
import Logo from './Logo'

function Krowdy({ view }) {
  const classes = useStyles()

  return (
    <Paper className={classes.paper} >
      <Logo />
      <div className={classes.ContainerView}>
        {view}
      </div>
      <Legalbase />
    </Paper>
  )
}

const useStyles = makeStyles(theme => ({
  ContainerView: {
    '@media screen and (max-height: 832px)': {
      display: 'block',
      height : 'auto',
      width  : 440
    },
    alignItems: 'center',
    display   : 'flex',
    maxWidth  : '90%'
  },
  paper: {
    '@media screen and (max-width: 1279px)': {
      boxShadow     : 'none',
      display       : 'flex',
      flexDirection : 'column',
      justifyContent: 'space-around',
      margin        : 'auto',
      paddingTop    : 0,
      width         : '95%'
    },
    alignItems    : 'center',
    borderRadius  : 8,
    boxShadow     : '1px 0px 10px 0px rgba(89, 89, 89, 0.4)',
    boxSizing     : 'border-box',
    color         : theme.palette.text.secondary,
    display       : 'flex',
    flex          : '1',
    flexDirection : 'column',
    justifyContent: 'inherit',
    overflow      : 'auto',
    paddingTop    : 'calc((100vh - 180px) / 12)',
    // eslint-disable-next-line no-dupe-keys
    paddingTop    : 'calc((var(--vh, 1vh) * 100  - 180px) / 16)',
    position      : 'relative',
    textAlign     : 'center'
  },
  root: {
    flexGrow: 1
  }
}))

export default Krowdy
