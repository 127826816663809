const buildUrlUri = data => {
  try {
    const keys = Object.keys(data)

    return keys.map(key => `${key}=${data[key]}`).join('&')
  } catch (error) {
    console.log('🚀 ~ error', error)
    throw error
  }
}

export { buildUrlUri }
